
export default {
  data() {
    return {
      isStage: false
    }
  },
  mounted() {
    // Меняем офомрмление меню если запущены ны DEV, TEST или STAGE
    if (window.location.hostname.match(/(dev|test|stage).macaroon.ru/)) {
      this.isStage = true
    }
  }
}
