import { render, staticRenderFns } from "./print-vertical.vue?vue&type=template&id=af2996b8"
import script from "./print-vertical.vue?vue&type=script&lang=js"
export * from "./print-vertical.vue?vue&type=script&lang=js"
import style0 from "./print-vertical.vue?vue&type=style&index=0&id=af2996b8&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_webpack@4.47.0__css-loader@5.2.7_webpack@4.47.0__lodash_j6w3xe2g2bvkgf5myu7k6oqzui/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports