import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0c9ac200 = () => interopDefault(import('../pages/certificates/index.vue' /* webpackChunkName: "pages/certificates/index" */))
const _972e583e = () => interopDefault(import('../pages/copy-excel-table.vue' /* webpackChunkName: "pages/copy-excel-table" */))
const _f5bbcc50 = () => interopDefault(import('../pages/costs/index.vue' /* webpackChunkName: "pages/costs/index" */))
const _f6d31cb2 = () => interopDefault(import('../pages/customers/index.vue' /* webpackChunkName: "pages/customers/index" */))
const _32e3ad7d = () => interopDefault(import('../pages/diadoc.vue' /* webpackChunkName: "pages/diadoc" */))
const _611952b8 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _0f220ccb = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _6c621cde = () => interopDefault(import('../pages/survey/index.vue' /* webpackChunkName: "pages/survey/index" */))
const _35be6678 = () => interopDefault(import('../pages/transits/index.vue' /* webpackChunkName: "pages/transits/index" */))
const _cc908232 = () => interopDefault(import('../pages/version.vue' /* webpackChunkName: "pages/version" */))
const _07a10e3a = () => interopDefault(import('../pages/admin/dashboard.vue' /* webpackChunkName: "pages/admin/dashboard" */))
const _7fa468be = () => interopDefault(import('../pages/admin/hello.vue' /* webpackChunkName: "pages/admin/hello" */))
const _19ceccb0 = () => interopDefault(import('../pages/admin/logs.vue' /* webpackChunkName: "pages/admin/logs" */))
const _c19b51b8 = () => interopDefault(import('../pages/admin/our-stock.vue' /* webpackChunkName: "pages/admin/our-stock" */))
const _8b748baa = () => interopDefault(import('../pages/admin/sidekiq.vue' /* webpackChunkName: "pages/admin/sidekiq" */))
const _314ffade = () => interopDefault(import('../pages/calc/cpt-ddp.vue' /* webpackChunkName: "pages/calc/cpt-ddp" */))
const _568f7924 = () => interopDefault(import('../pages/catalog/lists.vue' /* webpackChunkName: "pages/catalog/lists" */))
const _13c04fe2 = () => interopDefault(import('../pages/catalog/search/index.vue' /* webpackChunkName: "pages/catalog/search/index" */))
const _45c09536 = () => interopDefault(import('../pages/catalog/selection.vue' /* webpackChunkName: "pages/catalog/selection" */))
const _27a7194e = () => interopDefault(import('../pages/certificates/new.vue' /* webpackChunkName: "pages/certificates/new" */))
const _2508c8bc = () => interopDefault(import('../pages/ecom/discounted-products/index.vue' /* webpackChunkName: "pages/ecom/discounted-products/index" */))
const _7654db0f = () => interopDefault(import('../pages/ecom/online-products/index.vue' /* webpackChunkName: "pages/ecom/online-products/index" */))
const _33e5512a = () => interopDefault(import('../pages/ecom/orders/index.vue' /* webpackChunkName: "pages/ecom/orders/index" */))
const _65de44e2 = () => interopDefault(import('../pages/enrollment/orders/index.vue' /* webpackChunkName: "pages/enrollment/orders/index" */))
const _149f3dd2 = () => interopDefault(import('../pages/enrollment/transactions/index.vue' /* webpackChunkName: "pages/enrollment/transactions/index" */))
const _edeac1c8 = () => interopDefault(import('../pages/marketplace/offers.vue' /* webpackChunkName: "pages/marketplace/offers" */))
const _67dfc86d = () => interopDefault(import('../pages/marketplace/orders/index.vue' /* webpackChunkName: "pages/marketplace/orders/index" */))
const _425e5ee4 = () => interopDefault(import('../pages/marketplaces/dashboard.vue' /* webpackChunkName: "pages/marketplaces/dashboard" */))
const _2929c021 = () => interopDefault(import('../pages/marketplaces/dispatches/index.vue' /* webpackChunkName: "pages/marketplaces/dispatches/index" */))
const _8ac61cd8 = () => interopDefault(import('../pages/marketplaces/list.vue' /* webpackChunkName: "pages/marketplaces/list" */))
const _28b301c4 = () => interopDefault(import('../pages/marketplaces/orders/index.vue' /* webpackChunkName: "pages/marketplaces/orders/index" */))
const _2394727c = () => interopDefault(import('../pages/marketplaces/reports/index.vue' /* webpackChunkName: "pages/marketplaces/reports/index" */))
const _5ede0ff7 = () => interopDefault(import('../pages/pi/bank_accounts/index.vue' /* webpackChunkName: "pages/pi/bank_accounts/index" */))
const _1a017786 = () => interopDefault(import('../pages/pi/calc/index.vue' /* webpackChunkName: "pages/pi/calc/index" */))
const _173503b5 = () => interopDefault(import('../pages/pi/cost/index.vue' /* webpackChunkName: "pages/pi/cost/index" */))
const _10151a21 = () => interopDefault(import('../pages/pi/declarations/index.vue' /* webpackChunkName: "pages/pi/declarations/index" */))
const _623645e4 = () => interopDefault(import('../pages/pi/invoices/index.vue' /* webpackChunkName: "pages/pi/invoices/index" */))
const _a1d14a52 = () => interopDefault(import('../pages/pi/packing/index.vue' /* webpackChunkName: "pages/pi/packing/index" */))
const _34e795f2 = () => interopDefault(import('../pages/pi/shipments/index.vue' /* webpackChunkName: "pages/pi/shipments/index" */))
const _fe246fc2 = () => interopDefault(import('../pages/public/upload-images.vue' /* webpackChunkName: "pages/public/upload-images" */))
const _a3fd6e90 = () => interopDefault(import('../pages/purchases/arrivals.vue' /* webpackChunkName: "pages/purchases/arrivals" */))
const _67ac04e2 = () => interopDefault(import('../pages/purchases/extra.vue' /* webpackChunkName: "pages/purchases/extra" */))
const _0af86c37 = () => interopDefault(import('../pages/reports/delta.vue' /* webpackChunkName: "pages/reports/delta" */))
const _a9a9e74a = () => interopDefault(import('../pages/reports/inventory.vue' /* webpackChunkName: "pages/reports/inventory" */))
const _962d25ca = () => interopDefault(import('../pages/reports/mac_sales.vue' /* webpackChunkName: "pages/reports/mac_sales" */))
const _d8e68c88 = () => interopDefault(import('../pages/reports/report1.vue' /* webpackChunkName: "pages/reports/report1" */))
const _49423576 = () => interopDefault(import('../pages/sales/current.vue' /* webpackChunkName: "pages/sales/current" */))
const _38040ea3 = () => interopDefault(import('../pages/sales/customizable/index.vue' /* webpackChunkName: "pages/sales/customizable/index" */))
const _249c9ffc = () => interopDefault(import('../pages/sales/real.vue' /* webpackChunkName: "pages/sales/real" */))
const _ad2a980c = () => interopDefault(import('../pages/sales/reported/index.vue' /* webpackChunkName: "pages/sales/reported/index" */))
const _b6d8a47c = () => interopDefault(import('../pages/stock/orders/index.vue' /* webpackChunkName: "pages/stock/orders/index" */))
const _5dcd3d4a = () => interopDefault(import('../pages/stock/packages/index.vue' /* webpackChunkName: "pages/stock/packages/index" */))
const _359bd101 = () => interopDefault(import('../pages/stock/products/index.vue' /* webpackChunkName: "pages/stock/products/index" */))
const _2d3a7798 = () => interopDefault(import('../pages/stock/serials/index.vue' /* webpackChunkName: "pages/stock/serials/index" */))
const _b836ae26 = () => interopDefault(import('../pages/stock/sn_addons.vue' /* webpackChunkName: "pages/stock/sn_addons" */))
const _96e7af70 = () => interopDefault(import('../pages/survey/check-winner.vue' /* webpackChunkName: "pages/survey/check-winner" */))
const _3e501578 = () => interopDefault(import('../pages/survey/participants.vue' /* webpackChunkName: "pages/survey/participants" */))
const _c5bd599c = () => interopDefault(import('../pages/survey/qrcode.vue' /* webpackChunkName: "pages/survey/qrcode" */))
const _91c105a2 = () => interopDefault(import('../pages/survey/questionnaire.vue' /* webpackChunkName: "pages/survey/questionnaire" */))
const _ab4b636a = () => interopDefault(import('../pages/zont/orders/index.vue' /* webpackChunkName: "pages/zont/orders/index" */))
const _3b4ed2f8 = () => interopDefault(import('../pages/catalog/configurator/builder.vue' /* webpackChunkName: "pages/catalog/configurator/builder" */))
const _308681e0 = () => interopDefault(import('../pages/catalog/configurator/models/index.vue' /* webpackChunkName: "pages/catalog/configurator/models/index" */))
const _864e1666 = () => interopDefault(import('../pages/catalog/configurator/offers/index.vue' /* webpackChunkName: "pages/catalog/configurator/offers/index" */))
const _3d891280 = () => interopDefault(import('../pages/catalog/configurator/platforms/index.vue' /* webpackChunkName: "pages/catalog/configurator/platforms/index" */))
const _69fbc5b0 = () => interopDefault(import('../pages/catalog/configurator/supplied-products.vue' /* webpackChunkName: "pages/catalog/configurator/supplied-products" */))
const _639e7a98 = () => interopDefault(import('../pages/catalog/zont/requests/index.vue' /* webpackChunkName: "pages/catalog/zont/requests/index" */))
const _7f4baf7e = () => interopDefault(import('../pages/ecom/online-products/provider-import.vue' /* webpackChunkName: "pages/ecom/online-products/provider-import" */))
const _59bd0a42 = () => interopDefault(import('../pages/marketplaces/offers/log.vue' /* webpackChunkName: "pages/marketplaces/offers/log" */))
const _4752cae6 = () => interopDefault(import('../pages/pi/declarations/import.vue' /* webpackChunkName: "pages/pi/declarations/import" */))
const _52bdbe30 = () => interopDefault(import('../pages/stock/inventory/audits/index.vue' /* webpackChunkName: "pages/stock/inventory/audits/index" */))
const _751e28ce = () => interopDefault(import('../pages/stock/serials/check.vue' /* webpackChunkName: "pages/stock/serials/check" */))
const _376754e6 = () => interopDefault(import('../pages/stock/serials/new.vue' /* webpackChunkName: "pages/stock/serials/new" */))
const _1fccde0e = () => interopDefault(import('../pages/stock/serials/parse.vue' /* webpackChunkName: "pages/stock/serials/parse" */))
const _5fc7c675 = () => interopDefault(import('../pages/catalog/configurator/offers/_id.vue' /* webpackChunkName: "pages/catalog/configurator/offers/_id" */))
const _c6b769a6 = () => interopDefault(import('../pages/catalog/configurator/platforms/_platform_id/index.vue' /* webpackChunkName: "pages/catalog/configurator/platforms/_platform_id/index" */))
const _26903bc8 = () => interopDefault(import('../pages/catalog/zont/requests/_id.vue' /* webpackChunkName: "pages/catalog/zont/requests/_id" */))
const _36a0f05a = () => interopDefault(import('../pages/stock/inventory/audits/_id/index.vue' /* webpackChunkName: "pages/stock/inventory/audits/_id/index" */))
const _4ca9bb5a = () => interopDefault(import('../pages/catalog/configurator/platforms/_platform_id/import.vue' /* webpackChunkName: "pages/catalog/configurator/platforms/_platform_id/import" */))
const _30f5687c = () => interopDefault(import('../pages/catalog/configurator/platforms/_platform_id/pricelist.vue' /* webpackChunkName: "pages/catalog/configurator/platforms/_platform_id/pricelist" */))
const _d4bf63c8 = () => interopDefault(import('../pages/catalog/configurator/platforms/_platform_id/products/index.vue' /* webpackChunkName: "pages/catalog/configurator/platforms/_platform_id/products/index" */))
const _26b139fa = () => interopDefault(import('../pages/stock/inventory/audits/_id/details.vue' /* webpackChunkName: "pages/stock/inventory/audits/_id/details" */))
const _7f86e49b = () => interopDefault(import('../pages/catalog/configurator/platforms/_platform_id/products/generate.vue' /* webpackChunkName: "pages/catalog/configurator/platforms/_platform_id/products/generate" */))
const _2ceda63e = () => interopDefault(import('../pages/catalog/configurator/platforms/_platform_id/products/tokenize.vue' /* webpackChunkName: "pages/catalog/configurator/platforms/_platform_id/products/tokenize" */))
const _66cb7942 = () => interopDefault(import('../pages/catalog/configurator/platforms/_platform_id/products/tokenize_from_price.vue' /* webpackChunkName: "pages/catalog/configurator/platforms/_platform_id/products/tokenize_from_price" */))
const _76b9fd6c = () => interopDefault(import('../pages/catalog/configurator/platforms/_platform_id/bases/_base_id.vue' /* webpackChunkName: "pages/catalog/configurator/platforms/_platform_id/bases/_base_id" */))
const _5e61fee7 = () => interopDefault(import('../pages/catalog/configurator/platforms/_platform_id/products/_pn.vue' /* webpackChunkName: "pages/catalog/configurator/platforms/_platform_id/products/_pn" */))
const _8f9c15f6 = () => interopDefault(import('../pages/catalog/product/_code.vue' /* webpackChunkName: "pages/catalog/product/_code" */))
const _015c286c = () => interopDefault(import('../pages/ecom/online-products/_product_id/index.vue' /* webpackChunkName: "pages/ecom/online-products/_product_id/index" */))
const _3fecd3dc = () => interopDefault(import('../pages/ecom/orders/_id.vue' /* webpackChunkName: "pages/ecom/orders/_id" */))
const _ef33c266 = () => interopDefault(import('../pages/enrollment/orders/_id/index.vue' /* webpackChunkName: "pages/enrollment/orders/_id/index" */))
const _e9bdc086 = () => interopDefault(import('../pages/enrollment/transactions/_id/index.vue' /* webpackChunkName: "pages/enrollment/transactions/_id/index" */))
const _67af0215 = () => interopDefault(import('../pages/marketplace/orders/_id.vue' /* webpackChunkName: "pages/marketplace/orders/_id" */))
const _8a89526e = () => interopDefault(import('../pages/marketplaces/dispatches/_id.vue' /* webpackChunkName: "pages/marketplaces/dispatches/_id" */))
const _83349df4 = () => interopDefault(import('../pages/marketplaces/orders/_id.vue' /* webpackChunkName: "pages/marketplaces/orders/_id" */))
const _15563838 = () => interopDefault(import('../pages/marketplaces/reports/_id.vue' /* webpackChunkName: "pages/marketplaces/reports/_id" */))
const _17b05762 = () => interopDefault(import('../pages/pi/bank_accounts/_id/index.vue' /* webpackChunkName: "pages/pi/bank_accounts/_id/index" */))
const _36bb86e8 = () => interopDefault(import('../pages/pi/declarations/_id/index.vue' /* webpackChunkName: "pages/pi/declarations/_id/index" */))
const _5eccc0f9 = () => interopDefault(import('../pages/pi/invoices/_id/index.vue' /* webpackChunkName: "pages/pi/invoices/_id/index" */))
const _759c26ff = () => interopDefault(import('../pages/pi/packing/_id.vue' /* webpackChunkName: "pages/pi/packing/_id" */))
const _f245c918 = () => interopDefault(import('../pages/pi/products/_id.vue' /* webpackChunkName: "pages/pi/products/_id" */))
const _323c65a2 = () => interopDefault(import('../pages/pi/shipments/_id.vue' /* webpackChunkName: "pages/pi/shipments/_id" */))
const _797b5dcb = () => interopDefault(import('../pages/sales/customizable/_id.vue' /* webpackChunkName: "pages/sales/customizable/_id" */))
const _5e296ce2 = () => interopDefault(import('../pages/sales/reported/_id.vue' /* webpackChunkName: "pages/sales/reported/_id" */))
const _f583eb9c = () => interopDefault(import('../pages/stock/packages/_id.vue' /* webpackChunkName: "pages/stock/packages/_id" */))
const _243c1fa9 = () => interopDefault(import('../pages/stock/products/_id.vue' /* webpackChunkName: "pages/stock/products/_id" */))
const _23b18000 = () => interopDefault(import('../pages/stock/serials/_id.vue' /* webpackChunkName: "pages/stock/serials/_id" */))
const _5fa5311a = () => interopDefault(import('../pages/zont/orders/_id.vue' /* webpackChunkName: "pages/zont/orders/_id" */))
const _71789642 = () => interopDefault(import('../pages/ecom/online-products/_product_id/edit.vue' /* webpackChunkName: "pages/ecom/online-products/_product_id/edit" */))
const _a06cd022 = () => interopDefault(import('../pages/enrollment/transactions/_id/edit.vue' /* webpackChunkName: "pages/enrollment/transactions/_id/edit" */))
const _261204cf = () => interopDefault(import('../pages/pi/bank_accounts/_id/new_transaction.vue' /* webpackChunkName: "pages/pi/bank_accounts/_id/new_transaction" */))
const _2d0070aa = () => interopDefault(import('../pages/pi/declarations/_id/duties.vue' /* webpackChunkName: "pages/pi/declarations/_id/duties" */))
const _e4b8ce60 = () => interopDefault(import('../pages/pi/invoices/_id/new-shipment.vue' /* webpackChunkName: "pages/pi/invoices/_id/new-shipment" */))
const _226b5101 = () => interopDefault(import('../pages/ecom/online-products/_product_id/discounted-products/_discounted_product_id.vue' /* webpackChunkName: "pages/ecom/online-products/_product_id/discounted-products/_discounted_product_id" */))
const _2d7df035 = () => interopDefault(import('../pages/pi/calc/_calc_type/_id.vue' /* webpackChunkName: "pages/pi/calc/_calc_type/_id" */))
const _a2da9c62 = () => interopDefault(import('../pages/customers/_id.vue' /* webpackChunkName: "pages/customers/_id" */))
const _4cfa5d40 = () => interopDefault(import('../pages/deliveries/_docId.vue' /* webpackChunkName: "pages/deliveries/_docId" */))
const _0b95adf8 = () => interopDefault(import('../pages/documentation/_slug.vue' /* webpackChunkName: "pages/documentation/_slug" */))
const _3712a338 = () => interopDefault(import('../pages/marketplaces/_market_id/index.vue' /* webpackChunkName: "pages/marketplaces/_market_id/index" */))
const _7f902dd9 = () => interopDefault(import('../pages/ocs/_id.vue' /* webpackChunkName: "pages/ocs/_id" */))
const _0d799fa8 = () => interopDefault(import('../pages/transits/_id.vue' /* webpackChunkName: "pages/transits/_id" */))
const _22d70cee = () => interopDefault(import('@/pages/catalog/search/index.vue' /* webpackChunkName: "" */))
const _37e3003e = () => interopDefault(import('@/pages/catalog/product/_code.vue' /* webpackChunkName: "" */))
const _1290a10e = () => interopDefault(import('@/pages/catalog/configurator/platforms/_platform_id/products/_pn.vue' /* webpackChunkName: "" */))
const _1600d1ff = () => interopDefault(import('@/pages/marketplaces/orders/_id.vue' /* webpackChunkName: "" */))
const _c509597c = () => interopDefault(import('@/pages/marketplaces/dispatches/_id.vue' /* webpackChunkName: "" */))
const _5bdc825a = () => interopDefault(import('@/pages/marketplaces/dispatches/index.vue' /* webpackChunkName: "" */))
const _921ca306 = () => interopDefault(import('@/pages/documentation/_slug.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/certificates",
    component: _0c9ac200,
    name: "certificates"
  }, {
    path: "/copy-excel-table",
    component: _972e583e,
    name: "copy-excel-table"
  }, {
    path: "/costs",
    component: _f5bbcc50,
    name: "costs"
  }, {
    path: "/customers",
    component: _f6d31cb2,
    name: "customers"
  }, {
    path: "/diadoc",
    component: _32e3ad7d,
    name: "diadoc"
  }, {
    path: "/login",
    component: _611952b8,
    name: "login"
  }, {
    path: "/logout",
    component: _0f220ccb,
    name: "logout"
  }, {
    path: "/survey",
    component: _6c621cde,
    name: "survey"
  }, {
    path: "/transits",
    component: _35be6678,
    name: "transits"
  }, {
    path: "/version",
    component: _cc908232,
    name: "version"
  }, {
    path: "/admin/dashboard",
    component: _07a10e3a,
    name: "admin-dashboard"
  }, {
    path: "/admin/hello",
    component: _7fa468be,
    name: "admin-hello"
  }, {
    path: "/admin/logs",
    component: _19ceccb0,
    name: "admin-logs"
  }, {
    path: "/admin/our-stock",
    component: _c19b51b8,
    name: "admin-our-stock"
  }, {
    path: "/admin/sidekiq",
    component: _8b748baa,
    name: "admin-sidekiq"
  }, {
    path: "/calc/cpt-ddp",
    component: _314ffade,
    name: "calc-cpt-ddp"
  }, {
    path: "/catalog/lists",
    component: _568f7924,
    name: "catalog-lists"
  }, {
    path: "/catalog/search",
    component: _13c04fe2,
    name: "catalog-search"
  }, {
    path: "/catalog/selection",
    component: _45c09536,
    name: "catalog-selection"
  }, {
    path: "/certificates/new",
    component: _27a7194e,
    name: "certificates-new"
  }, {
    path: "/ecom/discounted-products",
    component: _2508c8bc,
    name: "ecom-discounted-products"
  }, {
    path: "/ecom/online-products",
    component: _7654db0f,
    name: "ecom-online-products"
  }, {
    path: "/ecom/orders",
    component: _33e5512a,
    name: "ecom-orders"
  }, {
    path: "/enrollment/orders",
    component: _65de44e2,
    name: "enrollment-orders"
  }, {
    path: "/enrollment/transactions",
    component: _149f3dd2,
    name: "enrollment-transactions"
  }, {
    path: "/marketplace/offers",
    component: _edeac1c8,
    name: "marketplace-offers"
  }, {
    path: "/marketplace/orders",
    component: _67dfc86d,
    name: "marketplace-orders"
  }, {
    path: "/marketplaces/dashboard",
    component: _425e5ee4,
    name: "marketplaces-dashboard"
  }, {
    path: "/marketplaces/dispatches",
    component: _2929c021,
    name: "marketplaces-dispatches"
  }, {
    path: "/marketplaces/list",
    component: _8ac61cd8,
    name: "marketplaces-list"
  }, {
    path: "/marketplaces/orders",
    component: _28b301c4,
    name: "marketplaces-orders"
  }, {
    path: "/marketplaces/reports",
    component: _2394727c,
    name: "marketplaces-reports"
  }, {
    path: "/pi/bank_accounts",
    component: _5ede0ff7,
    name: "pi-bank_accounts"
  }, {
    path: "/pi/calc",
    component: _1a017786,
    name: "pi-calc"
  }, {
    path: "/pi/cost",
    component: _173503b5,
    name: "pi-cost"
  }, {
    path: "/pi/declarations",
    component: _10151a21,
    name: "pi-declarations"
  }, {
    path: "/pi/invoices",
    component: _623645e4,
    name: "pi-invoices"
  }, {
    path: "/pi/packing",
    component: _a1d14a52,
    name: "pi-packing"
  }, {
    path: "/pi/shipments",
    component: _34e795f2,
    name: "pi-shipments"
  }, {
    path: "/public/upload-images",
    component: _fe246fc2,
    name: "public-upload-images"
  }, {
    path: "/purchases/arrivals",
    component: _a3fd6e90,
    name: "purchases-arrivals"
  }, {
    path: "/purchases/extra",
    component: _67ac04e2,
    name: "purchases-extra"
  }, {
    path: "/reports/delta",
    component: _0af86c37,
    name: "reports-delta"
  }, {
    path: "/reports/inventory",
    component: _a9a9e74a,
    name: "reports-inventory"
  }, {
    path: "/reports/mac_sales",
    component: _962d25ca,
    name: "reports-mac_sales"
  }, {
    path: "/reports/report1",
    component: _d8e68c88,
    name: "reports-report1"
  }, {
    path: "/sales/current",
    component: _49423576,
    name: "sales-current"
  }, {
    path: "/sales/customizable",
    component: _38040ea3,
    name: "sales-customizable"
  }, {
    path: "/sales/real",
    component: _249c9ffc,
    name: "sales-real"
  }, {
    path: "/sales/reported",
    component: _ad2a980c,
    name: "sales-reported"
  }, {
    path: "/stock/orders",
    component: _b6d8a47c,
    name: "stock-orders"
  }, {
    path: "/stock/packages",
    component: _5dcd3d4a,
    name: "stock-packages"
  }, {
    path: "/stock/products",
    component: _359bd101,
    name: "stock-products"
  }, {
    path: "/stock/serials",
    component: _2d3a7798,
    name: "stock-serials"
  }, {
    path: "/stock/sn_addons",
    component: _b836ae26,
    name: "stock-sn_addons"
  }, {
    path: "/survey/check-winner",
    component: _96e7af70,
    name: "survey-check-winner"
  }, {
    path: "/survey/participants",
    component: _3e501578,
    name: "survey-participants"
  }, {
    path: "/survey/qrcode",
    component: _c5bd599c,
    name: "survey-qrcode"
  }, {
    path: "/survey/questionnaire",
    component: _91c105a2,
    name: "survey-questionnaire"
  }, {
    path: "/zont/orders",
    component: _ab4b636a,
    name: "zont-orders"
  }, {
    path: "/catalog/configurator/builder",
    component: _3b4ed2f8,
    name: "catalog-configurator-builder"
  }, {
    path: "/catalog/configurator/models",
    component: _308681e0,
    name: "catalog-configurator-models"
  }, {
    path: "/catalog/configurator/offers",
    component: _864e1666,
    name: "catalog-configurator-offers"
  }, {
    path: "/catalog/configurator/platforms",
    component: _3d891280,
    name: "catalog-configurator-platforms"
  }, {
    path: "/catalog/configurator/supplied-products",
    component: _69fbc5b0,
    name: "catalog-configurator-supplied-products"
  }, {
    path: "/catalog/zont/requests",
    component: _639e7a98,
    name: "catalog-zont-requests"
  }, {
    path: "/ecom/online-products/provider-import",
    component: _7f4baf7e,
    name: "ecom-online-products-provider-import"
  }, {
    path: "/marketplaces/offers/log",
    component: _59bd0a42,
    name: "marketplaces-offers-log"
  }, {
    path: "/pi/declarations/import",
    component: _4752cae6,
    name: "pi-declarations-import"
  }, {
    path: "/stock/inventory/audits",
    component: _52bdbe30,
    name: "stock-inventory-audits"
  }, {
    path: "/stock/serials/check",
    component: _751e28ce,
    name: "stock-serials-check"
  }, {
    path: "/stock/serials/new",
    component: _376754e6,
    name: "stock-serials-new"
  }, {
    path: "/stock/serials/parse",
    component: _1fccde0e,
    name: "stock-serials-parse"
  }, {
    path: "/catalog/configurator/offers/:id",
    component: _5fc7c675,
    name: "catalog-configurator-offers-id"
  }, {
    path: "/catalog/configurator/platforms/:platform_id",
    component: _c6b769a6,
    name: "catalog-configurator-platforms-platform_id"
  }, {
    path: "/catalog/zont/requests/:id",
    component: _26903bc8,
    name: "catalog-zont-requests-id"
  }, {
    path: "/stock/inventory/audits/:id",
    component: _36a0f05a,
    name: "stock-inventory-audits-id"
  }, {
    path: "/catalog/configurator/platforms/:platform_id/import",
    component: _4ca9bb5a,
    name: "catalog-configurator-platforms-platform_id-import"
  }, {
    path: "/catalog/configurator/platforms/:platform_id/pricelist",
    component: _30f5687c,
    name: "catalog-configurator-platforms-platform_id-pricelist"
  }, {
    path: "/catalog/configurator/platforms/:platform_id/products",
    component: _d4bf63c8,
    name: "catalog-configurator-platforms-platform_id-products"
  }, {
    path: "/stock/inventory/audits/:id/details",
    component: _26b139fa,
    name: "stock-inventory-audits-id-details"
  }, {
    path: "/catalog/configurator/platforms/:platform_id/products/generate",
    component: _7f86e49b,
    name: "catalog-configurator-platforms-platform_id-products-generate"
  }, {
    path: "/catalog/configurator/platforms/:platform_id/products/tokenize",
    component: _2ceda63e,
    name: "catalog-configurator-platforms-platform_id-products-tokenize"
  }, {
    path: "/catalog/configurator/platforms/:platform_id/products/tokenize_from_price",
    component: _66cb7942,
    name: "catalog-configurator-platforms-platform_id-products-tokenize_from_price"
  }, {
    path: "/catalog/configurator/platforms/:platform_id/bases/:base_id?",
    component: _76b9fd6c,
    name: "catalog-configurator-platforms-platform_id-bases-base_id"
  }, {
    path: "/catalog/configurator/platforms/:platform_id/products/:pn",
    component: _5e61fee7,
    name: "catalog-configurator-platforms-platform_id-products-pn"
  }, {
    path: "/catalog/product/:code?",
    component: _8f9c15f6,
    name: "catalog-product-code"
  }, {
    path: "/ecom/online-products/:product_id",
    component: _015c286c,
    name: "ecom-online-products-product_id"
  }, {
    path: "/ecom/orders/:id",
    component: _3fecd3dc,
    name: "ecom-orders-id"
  }, {
    path: "/enrollment/orders/:id",
    component: _ef33c266,
    name: "enrollment-orders-id"
  }, {
    path: "/enrollment/transactions/:id",
    component: _e9bdc086,
    name: "enrollment-transactions-id"
  }, {
    path: "/marketplace/orders/:id",
    component: _67af0215,
    name: "marketplace-orders-id"
  }, {
    path: "/marketplaces/dispatches/:id",
    component: _8a89526e,
    name: "marketplaces-dispatches-id"
  }, {
    path: "/marketplaces/orders/:id",
    component: _83349df4,
    name: "marketplaces-orders-id"
  }, {
    path: "/marketplaces/reports/:id",
    component: _15563838,
    name: "marketplaces-reports-id"
  }, {
    path: "/pi/bank_accounts/:id",
    component: _17b05762,
    name: "pi-bank_accounts-id"
  }, {
    path: "/pi/declarations/:id",
    component: _36bb86e8,
    name: "pi-declarations-id"
  }, {
    path: "/pi/invoices/:id",
    component: _5eccc0f9,
    name: "pi-invoices-id"
  }, {
    path: "/pi/packing/:id",
    component: _759c26ff,
    name: "pi-packing-id"
  }, {
    path: "/pi/products/:id?",
    component: _f245c918,
    name: "pi-products-id"
  }, {
    path: "/pi/shipments/:id",
    component: _323c65a2,
    name: "pi-shipments-id"
  }, {
    path: "/sales/customizable/:id",
    component: _797b5dcb,
    name: "sales-customizable-id"
  }, {
    path: "/sales/reported/:id",
    component: _5e296ce2,
    name: "sales-reported-id"
  }, {
    path: "/stock/packages/:id",
    component: _f583eb9c,
    name: "stock-packages-id"
  }, {
    path: "/stock/products/:id",
    component: _243c1fa9,
    name: "stock-products-id"
  }, {
    path: "/stock/serials/:id",
    component: _23b18000,
    name: "stock-serials-id"
  }, {
    path: "/zont/orders/:id",
    component: _5fa5311a,
    name: "zont-orders-id"
  }, {
    path: "/ecom/online-products/:product_id?/edit",
    component: _71789642,
    name: "ecom-online-products-product_id-edit"
  }, {
    path: "/enrollment/transactions/:id/edit",
    component: _a06cd022,
    name: "enrollment-transactions-id-edit"
  }, {
    path: "/pi/bank_accounts/:id/new_transaction",
    component: _261204cf,
    name: "pi-bank_accounts-id-new_transaction"
  }, {
    path: "/pi/declarations/:id/duties",
    component: _2d0070aa,
    name: "pi-declarations-id-duties"
  }, {
    path: "/pi/invoices/:id/new-shipment",
    component: _e4b8ce60,
    name: "pi-invoices-id-new-shipment"
  }, {
    path: "/ecom/online-products/:product_id?/discounted-products/:discounted_product_id",
    component: _226b5101,
    name: "ecom-online-products-product_id-discounted-products-discounted_product_id"
  }, {
    path: "/pi/calc/:calc_type/:id?",
    component: _2d7df035,
    name: "pi-calc-calc_type-id"
  }, {
    path: "/customers/:id",
    component: _a2da9c62,
    name: "customers-id"
  }, {
    path: "/deliveries/:docId?",
    component: _4cfa5d40,
    name: "deliveries-docId"
  }, {
    path: "/documentation/:slug?",
    component: _0b95adf8,
    name: "documentation-slug"
  }, {
    path: "/marketplaces/:market_id",
    component: _3712a338,
    name: "marketplaces-market_id"
  }, {
    path: "/ocs/:id?",
    component: _7f902dd9,
    name: "ocs-id"
  }, {
    path: "/transits/:id",
    component: _0d799fa8,
    name: "transits-id"
  }, {
    path: "/",
    component: _22d70cee,
    name: "Index"
  }, {
    path: "/catalog/product/:code(.*)",
    component: _37e3003e,
    name: "CatalogProduct"
  }, {
    path: "/catalog/configurator/platforms/:platform_id/products/:pn(.*)",
    component: _1290a10e,
    name: "PlatformProduct"
  }, {
    path: "/catalog/platforms/:platformId/search",
    component: _22d70cee,
    name: "searchByPlatform"
  }, {
    path: "/stock/orders/:id",
    component: _1600d1ff,
    name: "StockOnlineOrder"
  }, {
    path: "/stock/dispatches/:id",
    component: _c509597c,
    name: "StockDispatch"
  }, {
    path: "/stock/dispatches/",
    component: _5bdc825a,
    name: "StockDispatches"
  }, {
    path: "/documentation/:slug*",
    component: _921ca306,
    name: "DocumentationSlug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
