import { render, staticRenderFns } from "./survey.vue?vue&type=template&id=a80e790a&scoped=true"
var script = {}
import style0 from "./survey.vue?vue&type=style&index=0&id=a80e790a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_webpack@4.47.0__css-loader@5.2.7_webpack@4.47.0__lodash_j6w3xe2g2bvkgf5myu7k6oqzui/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a80e790a",
  null
  
)

export default component.exports